$ggap: 0.75rem;
$ggap2: $ggap * 2.5;
$ggap3: $ggap2 * 2;

$breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px,
);

@mixin respond-to($breakpoint) {
  @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

@keyframes animateWght {
  from {
    font-variation-settings: "wght" 300;
  }
  to {
    font-variation-settings: "wght" var(--wght);
  }
}

@keyframes showFig {
  0% {
    transform: translateY(calc(100% + var(--y1))) rotate(var(--r1));
  }
  97% {
    transform: translateY(calc(-20% + var(--y2))) rotate(var(--r2));
  }
  100% {
    transform: translateY(var(--y3)) rotate(0);
  }
}

@keyframes showFigReverse {
  0% {
    transform: translateY(calc(100% + var(--y1))) rotate(var(--r1));
  }
  97% {
    transform: translateY(calc(-20% + var(--y2))) rotate(var(--r2));
  }
  100% {
    transform: translateY(var(--y3)) rotate(0);
  }
}

@mixin variable {
  .name span {
    font-variation-settings: "wght" var(--wght);
  }
}

@mixin onetimevarible {
  .name span {
    transition: none;
    animation: animateWght 0.8s 0.3s forwards;
  }
}

@mixin figanim {
  animation: showFig var(--du) var(--de) both steps(6, jump-none);
}

@mixin hidefiganim {
  animation: showFigReverse var(--du) var(--de) both steps(6, jump-none) reverse;
}
