@font-face {
  font-family: "disp";
  src: url(../fonts/BBBVF.woff2) format("woff2");
}

@font-face {
  font-family: "sans";
  src: url(../fonts/standard-book-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sans";
  src: url(../fonts/standard-book-italic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "sans";
  src: url(../fonts/standard-bold-webfont.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "sans";
  src: url(../fonts/standard-bold-italic-webfont.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}
